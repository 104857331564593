import {Theme, Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";

interface Props {
    title: string;
    icon: string;
    link: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    socialIcon: {
        margin: "1rem",
        height: "5rem",
        width: "5rem",
        borderRadius: "50%",
        transition: theme.transitions.create(["height", "width", "margin"]),
        [theme.breakpoints.only("xs")]: {
            height: "3rem",
            width: "3rem",
            margin: "0.5rem"
        }
    },
    tooltip: {
        fontSize: "0.8rem",
        fontFamily: "Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif"
    }
}));

const SocialMediaIcon: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <a
            title={props.title}
            target="_blank"
            href={props.link}>

            <Tooltip
                arrow
                title={props.title}
                placement="top"
                enterDelay={0}
                classes={{tooltip: classes.tooltip}}>

                <img
                    className={classes.socialIcon}
                    src={props.icon}
                    alt={props.title}
                />

            </Tooltip>

        </a>
    );
};

export default SocialMediaIcon;
