import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import SectionHeading from "../typography/SectionHeading";
import IntroItemContainer, {IntroItem} from "./IntroItemContainer";

const useStyles = makeStyles((theme: Theme) => ({
    subtitle: {
        ...theme.typography.body1,
        textAlign: "center",
        margin: "0rem auto 3rem auto",
        transition: theme.transitions.create(["margin", "font-size"]),
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1rem",
            margin: "1.5rem auto 2.6rem auto"
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.0rem",
            margin: "1.25rem auto 2.2rem auto"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.9rem",
            margin: "1rem auto 1.8rem auto"
        }
    },
    title: {
        textAlign: "center",
        margin: "2rem 0rem",
        color: "rgba(0,0,0,0.87)"
    }
}));

export interface PageIntroContentProps {
    title: string;
    description?: string;
    items: IntroItem[];
}

const PageIntroContent: React.FC<PageIntroContentProps> = props => {
    const classes = useStyles();

    return (
        <>
            <SectionHeading
                title={props.title}
                className={classes.title}/>

            {props.description && (
                <span
                    dangerouslySetInnerHTML={{__html: props.description}}
                    className={classes.subtitle}/>
            )}

            <IntroItemContainer
                items={props.items}/>

        </>
    );
};

export default PageIntroContent;
