import React, {FC} from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import Template from "./Template";

const Page: FC = () => {
    return (
        <Layout>

            <SEO
                title="Test your workflows"
                keywords={["FlowCov", "workflow testing", "workflow coverage"]}
                description="Improve your workflows by testing them just as you test your code. FlowCov provides highly integrated tools to create, merge, archive and display workflow coverage reports."/>

            <Template/>

        </Layout>
    );
};

export default Page;
