import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import SectionContainer from "../layout/SectionContainer";
import PageIntroContent, {PageIntroContentProps} from "./PageIntroContent";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        paddingTop: "0rem",
        paddingBottom: "1rem"
    },
    box: {
        display: "flex",
        flexDirection: "column"
    },
    outerContainer: {
        backgroundColor: "#F2F2F2"
    }
}));

const DetailPageIntro: React.FC<PageIntroContentProps> = props => {
    const classes = useStyles();

    return (
        <div className={classes.outerContainer}>

            <SectionContainer className={classes.container}>

                <div className={classes.box}>

                    <PageIntroContent {...props} />

                </div>

            </SectionContainer>

        </div>
    );
};

export default DetailPageIntro;