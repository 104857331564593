import {Container} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: "40px 30px",
        transition: theme.transitions.create("padding"),
        [theme.breakpoints.only("md")]: {
            padding: "30px 20px"
        },
        [theme.breakpoints.only("sm")]: {
            padding: "20px 15px"
        },
        [theme.breakpoints.only("xs")]: {
            padding: "10px 10px"
        }
    },
}));

interface Props {
    className?: string;
}

const SectionContainer: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <Container className={clsx(classes.container, props.className)}>
            {props.children}
        </Container>
    );
}

export default SectionContainer;