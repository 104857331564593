import {Button, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "gatsby";
import React, {FC} from "react";
import ContactSection from "../../components/contact/ContactSection";
import DetailsSection from "../../components/details/DetailsSection";
import MainPageHeader from "../../components/header/MainPageHeader";
import DetailPageIntro from "../../components/intro/DetailPageIntro";
import SectionHeading from "../../components/typography/SectionHeading";
import logo from "../../img/logo/logo-icon.svg"

const useStyles = makeStyles((theme: Theme) => ({
    text: {
        color: theme.palette.text.primary,
        textAlign: "justify",
        fontSize: "1rem",
    },
    check: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        marginRight: "0.75rem",
        fontStyle: "initial",
        fontSize: "1.4rem"
    },
    bullets: {
        color: theme.palette.text.primary,
        margin: "1rem",
        fontSize: "1rem",
        textAlign: "left"
    },
    bullet: {
        display: "flex",
        alignItems: "center",
        marginTop: "0.25rem"
    },
    arrow: {
        marginLeft: "0.5rem"
    },
    outerLink: {
        textDecoration: "none"
    },
    link: {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        fontWeight: "bold",
        fontSize: "1.1rem",
        textAlign: "left",
        marginBottom: "0.25rem",
        color: "#3F51B5"
    },
    button: {
        whiteSpace: "nowrap",
        textTransform: "none",
        fontSize: "1.2rem",
        letterSpacing: "0.05rem",
        fontWeight: 600,
        flexShrink: 0,
        marginLeft: "2rem",
        padding: "0.75rem 1.5rem",
        transition: theme.transitions.create("font-size"),
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1rem"
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.0rem"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.9rem"
        }
    },
    buttonContainer: {
        backgroundColor: "#F2F2F2",
        display: "flex",
        justifyContent: "center",
        margin: "2rem 0rem 4rem 0rem",
        padding: "3rem",
        alignItems: "center",
        flexShrink: 1
    },
    buttonText: {
        margin: 0
    },
    banner: {
        color: "rgba(255, 255, 255, 0.87)",
        textAlign: "center"
    },
    bannerLink: {
        color: "rgba(255, 255, 255, 0.87)",
        fontWeight: "bold"
    }
}))

const Template: FC = () => {
    const classes = useStyles();

    return (
        <>

            <MainPageHeader
                buttonLink="https://docs.flowcov.io"
                buttonText="Get Started For Free"
                claim="Improve your workflow and decision models by testing them just as you test your code.
                    FlowCov provides highly integrated tools to create, merge, archive and display coverage reports."
                logo={logo}/>

            <DetailPageIntro
                title=""
                description=""
                items={[
                    {
                        title: "Create coverage reports easily",
                        description: "Create coverage reports for BPMN and DMN models simply by adding our testing library.",
                        icon: "/img/index/index-intro-models.png"
                    },
                    {
                        title: "Integrates into every CI provider",
                        description: "FlowCov works out-of-the-box with all major CI providers and only requires a shell.",
                        icon: "/img/index/index-intro-ci.png"
                    },
                    {
                        title: "Upload reports with one line",
                        icon: "/img/index/index-intro-upload.png",
                        description: "Add our uploading script to your pipeline and see detailed coverage reports in seconds."
                    }
                ]}/>

            <DetailsSection
                items={[
                    {
                        image: "/img/index/index-details-coverage.png",
                        text: (
                            <>
                                <Typography
                                    className={classes.text}
                                    variant="body2">
                                    Simply add our testing library to your projects and generate coverage reports with
                                    every build.
                                </Typography>
                                <Typography
                                    className={classes.bullets}
                                    variant="body2">
                                    <span className={classes.bullet}>
                                        <i className={classes.check}>&#x2713;</i>
                                        Generate reports by adding just a few lines of code
                                    </span>
                                    <span className={classes.bullet}>
                                        <i className={classes.check}>&#x2713;</i>
                                        Support for Camunda, BPMN 2.0 &amp; DMN 1.1
                                    </span>
                                    <span className={classes.bullet}>
                                        <i className={classes.check}>&#x2713;</i>
                                        Detailed coverage reports per unit test
                                    </span>
                                </Typography>
                                <Link
                                    className={classes.outerLink}
                                    to="https://docs.flowcov.io/getting-started/creating-reports">
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.link}>
                                        Learn more about our testing library
                                        <img
                                            className={classes.arrow}
                                            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3LjE5NSA1LjkyOEwxMS4zOTcuMTAyYS4zNS4zNSAwIDAgMC0uNDk1IDBMOS40NTUgMS41NTdhLjM1Ni4zNTYgMCAwIDAgMCAuNDk5czMuNDYxIDMuNDUyIDMuNDQ2IDMuNDY3SC4zNWEuMzUuMzUgMCAwIDAtLjM1LjM1MnYyLjA1N2EuMzUuMzUgMCAwIDAgLjM1LjM1M2wxMi41NjUtLjAwOS0zLjY1MSAzLjY2OWEuMzU0LjM1NCAwIDAgMCAwIC40OTdsMS40NDcgMS40NTRhLjM0Ny4zNDcgMCAwIDAgLjQ5NSAwbDYuNTkyLTYuNjIyYy4yNC0uMjQuMjc2LS40NDUuMDU1LS42NjdsLS42NTgtLjY3OXoiIGZpbGw9IiMzRjUxQjUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg=="/>
                                    </Typography>
                                </Link>
                            </>
                        ),
                        title: "Create coverage reports in minutes"
                    },
                    {
                        image: "/img/index/index-details-merge.png",
                        text: (
                            <>
                                <Typography
                                    className={classes.text}
                                    variant="body2">
                                    Merge all of your reports and get a coverage dashboard with grouping at model,
                                    class, and method level.
                                </Typography>
                                <Typography
                                    className={classes.bullets}
                                    variant="body2">
                                    <span className={classes.bullet}>
                                        <i className={classes.check}>&#x2713;</i>
                                        Integrate the upload in your CI with our script
                                    </span>
                                    <span className={classes.bullet}>
                                        <i className={classes.check}>&#x2713;</i>
                                        Find out how your coverage has changed over time
                                    </span>
                                    <span className={classes.bullet}>
                                        <i className={classes.check}>&#x2713;</i>
                                        Explore your workflow coverage per model, class, and method
                                    </span>
                                    <span className={classes.bullet}>
                                        <i className={classes.check}>&#x2713;</i>
                                        Visualize your models' transaction boundaries and expressions
                                    </span>
                                    <span className={classes.bullet}>
                                        <i className={classes.check}>&#x2713;</i>
                                        Replay your test methods to see how your process behaves in detail
                                    </span>
                                </Typography>
                                <Link
                                    className={classes.outerLink}
                                    to="https://docs.flowcov.io/getting-started/uploading-reports">
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.link}>
                                        Learn more about our uploading script
                                        <img
                                            className={classes.arrow}
                                            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3LjE5NSA1LjkyOEwxMS4zOTcuMTAyYS4zNS4zNSAwIDAgMC0uNDk1IDBMOS40NTUgMS41NTdhLjM1Ni4zNTYgMCAwIDAgMCAuNDk5czMuNDYxIDMuNDUyIDMuNDQ2IDMuNDY3SC4zNWEuMzUuMzUgMCAwIDAtLjM1LjM1MnYyLjA1N2EuMzUuMzUgMCAwIDAgLjM1LjM1M2wxMi41NjUtLjAwOS0zLjY1MSAzLjY2OWEuMzU0LjM1NCAwIDAgMCAwIC40OTdsMS40NDcgMS40NTRhLjM0Ny4zNDcgMCAwIDAgLjQ5NSAwbDYuNTkyLTYuNjIyYy4yNC0uMjQuMjc2LS40NDUuMDU1LS42NjdsLS42NTgtLjY3OXoiIGZpbGw9IiMzRjUxQjUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg=="/>
                                    </Typography>
                                </Link>
                            </>
                        ),
                        title: "Upload and merge your reports"
                    }
                ]}/>

            <div className={classes.buttonContainer}>
                <SectionHeading
                    className={classes.buttonText}
                    title="Workflow coverage made simple. "/>
                <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    href="https://docs.flowcov.io">
                    Get Started Now
                </Button>
            </div>

            <ContactSection title="Contact Us"/>

        </>
    );
};

export default Template;
