import {Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import github from "../../img/social/colored/github_colored.svg";
import linkedIn from "../../img/social/colored/linkedin_colored.svg";
import medium from "../../img/social/colored/medium_colored.svg";
import twitter from "../../img/social/colored/twitter_colored.svg";
import xing from "../../img/social/colored/xing_colored.svg";
import SocialMediaIcon from "./SocialMediaIcon";

const useStyles = makeStyles((theme: Theme) => ({
    subtitle: {
        fontSize: "1rem",
        color: "rgba(0, 0, 0, 0.54)",
        transition: theme.transitions.create("font-size"),
        [theme.breakpoints.only("sm")]: {
            maxWidth: "600px",
            margin: "0 auto"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.9rem"
        }
    },
    socialContainer: {
        textAlign: "center",
        padding: "1rem"
    }
}));

const SocialMediaContainer: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.socialContainer}>

            <Typography className={classes.subtitle}>
                Find out more about us.
            </Typography>

            <p>

                <SocialMediaIcon
                    title="Twitter"
                    icon={twitter}
                    link="https://twitter.com/miragon_io/"/>

                <SocialMediaIcon
                    title="XING"
                    icon={xing}
                    link="https://xing.com/companies/flowsquadgmbh/"/>

                <SocialMediaIcon
                    title="LinkedIn"
                    icon={linkedIn}
                    link="https://linkedin.com/company/miragon-io/"/>

                <br/>

                <SocialMediaIcon
                    title="Medium"
                    icon={medium}
                    link="https://medium.com/@miragon/"/>

                <SocialMediaIcon
                    title="GitHub"
                    icon={github}
                    link="https://github.com/FlowSquad/"/>

            </p>

        </div>
    );
};

export default SocialMediaContainer;
