import {graphql, StaticQuery} from 'gatsby'
import * as React from 'react'
import Helmet from 'react-helmet'

interface SEOProps {
    description: string
    meta?: any[]
    keywords: string[]
    title: string
}

const SEO: React.SFC<SEOProps> = ({
                                      description,
                                      meta,
                                      keywords,
                                      title,
                                  }): JSX.Element => (
    <StaticQuery
        query={detailsQuery}
        render={data => {
            const metaDescription = description || data.site.siteMetadata.description
            return (
                <Helmet
                    htmlAttributes={{
                        lang: "en",
                    }}
                    title={title}
                    titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                    meta={[
                        {
                            content: metaDescription,
                            name: 'description',
                        },
                        {
                            content: title + " | Miragon",
                            property: 'og:title',
                        },
                        {
                            content: metaDescription,
                            property: 'og:description',
                        },
                        {
                            content: 'website',
                            property: 'og:type',
                        },
                        {
                            content: 'miragon.io',
                            property: 'og:site_name'
                        },
                        {
                            content: 'summary_large_image',
                            name: 'twitter:card',
                        },
                        {
                            content: '@miragon_io',
                            name: 'twitter:site',
                        },
                        {
                            content: '@miragon_io',
                            name: 'twitter:creator',
                        },
                        {
                            content: title + " | Miragon",
                            name: 'twitter:title',
                        },
                        {
                            content: metaDescription,
                            name: 'twitter:description',
                        },
                    ]
                        .concat(
                            keywords.length > 0
                                ? {
                                    content: keywords.join(', '),
                                    name: 'keywords',
                                }
                                : []
                        )
                        .concat(meta ? meta : [])}
                />
            )
        }}
    />
)

SEO.defaultProps = {
    keywords: [],
    lang: 'en',
    meta: [],
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
