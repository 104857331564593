import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {GatsbyImageProps} from "gatsby-image";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    itemContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 auto 1rem auto"
    },
    item: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "0.5rem 1.4rem",
        transition: theme.transitions.create("padding"),
        [theme.breakpoints.only("md")]: {
            padding: "0.4rem 0.9rem"
        },
        [theme.breakpoints.only("sm")]: {
            padding: "0.3rem 0.7rem"
        },
        [theme.breakpoints.only("xs")]: {
            padding: "0.3rem 0.5rem"
        }
    },
    image: {
        width: "100px",
        margin: "8px"
    },
    title: {
        fontWeight: "bold",
        color: theme.palette.text.primary,
        fontFamily: "Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif",
        textAlign: "center",
        fontSize: "1.1rem",
        letterSpacing: "-0.02rem",
        transition: theme.transitions.create("font-size"),
        [theme.breakpoints.down("md")]: {
            fontSize: "0.9rem"
        }
    },
    text: {
        ...theme.typography.body1,
        textAlign: "center",
        fontSize: "1rem",
        transition: theme.transitions.create("font-size"),
        [theme.breakpoints.down("md")]: {
            fontSize: "0.75rem",
        }
    }
}));

export interface IntroItem {
    icon: string;
    title: string;
    description: string;
}

interface Props {
    items: IntroItem[];
}

const IntroItemContainer = (props: Props) => {
    const classes = useStyles();

    return (
        <Grid container>

            {props.items.map(item => (

                <Grid
                    item
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    key={item.title}
                    className={classes.itemContainer}>

                    <img
                        className={classes.image}
                        src={item.icon}
                        alt=""/>

                    <div className={classes.item}>

                        <Typography
                            className={classes.title}
                            variant="h6">
                            {item.title}
                        </Typography>

                        <p className={classes.text}>
                            {item.description}
                        </p>

                    </div>

                </Grid>

            ))}

        </Grid>
    );
};

export default IntroItemContainer;
