import {Button, Typography} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import React from "react";
import BasicPageHeader from "./BasicPageHeader";

interface Props {
    logo: string;
    claim: string;
    buttonLink: string;
    buttonText: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: "column",
        transition: theme.transitions.create("height"),
        height: "640px",
        [theme.breakpoints.only("md")]: {
            height: "560px"
        },
        [theme.breakpoints.only("sm")]: {
            height: "480px"
        },
        [theme.breakpoints.only("xs")]: {
            height: "400px"
        }
    },
    logo: {
        transition: theme.transitions.create("width"),
        width: "300px",
        marginBottom: "1rem",
        [theme.breakpoints.only("md")]: {
            width: "250px"
        },
        [theme.breakpoints.only("sm")]: {
            width: "200px"
        },
        [theme.breakpoints.only("xs")]: {
            width: "150px"
        }
    },
    button: {
        textTransform: "none",
        fontSize: "1.2rem",
        letterSpacing: "0.05rem",
        fontWeight: 600,
        paddingLeft: "2rem",
        paddingRight: "2rem",
        transition: theme.transitions.create("font-size"),
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1rem"
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.0rem"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.9rem"
        }
    },
    claim: {
        color: theme.palette.text.primary,
        fontFamily: "Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif",
        marginBottom: theme.spacing(6),
        maxWidth: "740px",
        transition: theme.transitions.create(["font-size", "max-width"]),
        [theme.breakpoints.only("md")]: {
            fontSize: "1.4rem",
            maxWidth: "640px"
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2rem",
            maxWidth: "540px"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1rem",
            maxWidth: "440px"
        }
    }
}));

const MainPageHeader: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <BasicPageHeader containerClassName={classes.container}>

            <img
                alt=""
                src={props.logo}
                className={classes.logo}/>

            <Typography
                color="inherit"
                align="center"
                variant="h5"
                className={classes.claim}>
                {props.claim}
            </Typography>

            <Button
                disableElevation
                variant="contained"
                color="primary"
                className={classes.button}
                href={props.buttonLink}>

                {props.buttonText}

            </Button>

        </BasicPageHeader>
    );
};

export default MainPageHeader;
