import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import SectionContainer from "../layout/SectionContainer";
import SectionHeading from "../typography/SectionHeading";
import DetailsContainer, {DetailsItem} from "./DetailsContainer";

const useStyles = makeStyles(() => ({
    heading: {
        marginTop: "2rem",
        marginBottom: "0px"
    }
}));

interface Props {
    title?: string;
    items: DetailsItem[];
}

const DetailsSection: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <SectionContainer>

            {props.title && (
                <SectionHeading
                    title={props.title}
                    className={classes.heading}/>
            )}

            <DetailsContainer items={props.items}/>

        </SectionContainer>
    )
};

export default DetailsSection;