import {Button, Checkbox, FormControlLabel, TextField, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {navigate} from "gatsby-link";
import React, {ChangeEvent, useCallback, useState} from "react";

const encode = (data: any) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.only("sm")]: {
            maxWidth: "600px",
            margin: "0 auto"
        }
    },
    formField: {
        marginTop: "1rem",
        "&>div>fieldset": {
            transition: theme.transitions.create("border")
        }
    },
    checkbox: {
        marginTop: "1rem"
    },
    checkboxLabel: {
        display: "block",
        fontSize: "0.9rem",
        color: "rgba(0, 0, 0, 0.87)",
        "&>a": {
            color: "rgba(0, 0, 0, 0.87)"
        }
    },
    button: {
        fontFamily: "Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif",
        marginTop: "0.5rem",
        marginBottom: "2rem",
        textTransform: "none",
        fontSize: "1.2rem",
        letterSpacing: "-0.02rem",
        transition: theme.transitions.create("font-size"),
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1rem"
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.0rem"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.9rem"
        }
    },
    subtitle: {
        fontSize: "1rem",
        color: "rgba(0, 0, 0, 0.54)",
        marginBottom: "1rem",
        [theme.breakpoints.only("sm")]: {
            maxWidth: "600px",
            margin: "0 auto"
        },
        transition: theme.transitions.create("font-size"),
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.9rem"
        }
    }
}));

const ContactForm: React.FC = () => {
    const classes = useStyles();

    const [botField, setBotField] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const setBotFieldCallback = useCallback((e: ChangeEvent<HTMLInputElement>) => setBotField(e.target.value), []);
    const setNameCallback = useCallback((e: ChangeEvent<HTMLInputElement>) => setName(e.target.value), []);
    const setEmailCallback = useCallback((e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value), []);
    const setMessageCallback = useCallback((e: ChangeEvent<HTMLInputElement>) => setMessage(e.target.value), []);

    const onSubmit = useCallback((e: any) => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({
                "form-name": form.getAttribute("name"),
                name,
                email,
                botField,
                message
            })
        })
            .then(() => navigate(form.getAttribute("action")))
            .catch(error => alert(error));
    }, [name, email, message, botField]);

    return (
        <>
            <Typography className={classes.subtitle}>
                Have a question? Send us a message!
            </Typography>

            <form
                name="contact_flowcov"
                className={classes.form}
                data-netlify="true"
                data-netlify-honeypot="botField"
                action="/contact/thanks/"
                onSubmit={onSubmit}>

                <input type="hidden" name="form-name" value="contact"/>

                <div hidden>
                    <label>
                        Do not fill:&nbsp;
                        <input
                            name="botField"
                            onChange={setBotFieldCallback}
                            value={botField}/>
                    </label>
                </div>

                <TextField
                    className={classes.formField}
                    label="Your Name"
                    name="name"
                    id="name"
                    variant="outlined"
                    required
                    size="small"
                    value={name}
                    onChange={setNameCallback}/>

                <TextField
                    className={classes.formField}
                    label="Your Email"
                    type="email"
                    name="email"
                    id="email"
                    variant="outlined"
                    size="small"
                    required
                    value={email}
                    onChange={setEmailCallback}/>

                <TextField
                    className={classes.formField}
                    label="Your Message"
                    multiline
                    variant="outlined"
                    name="message"
                    size="small"
                    id="message"
                    required
                    rows="3"
                    value={message}
                    onChange={setMessageCallback}/>

                <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox required/>}
                    label={(
                        <span className={classes.checkboxLabel}>
                            I accept the <a href="https://www.miragon.io/datenschutz" target="_blank">Privacy Policy</a> for processing my message.
                        </span>
                    )}/>

                <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    type="submit">
                    Send Message
                </Button>

            </form>
        </>
    );
};

export default ContactForm;
