import Container from "@material-ui/core/Container";
import {makeStyles, Theme} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import background from "../../img/background.svg";

interface Props {
    containerClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        color: "white",
        position: "relative",
        display: "flex",
        alignItems: "center"
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    background: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        zIndex: -2
    }
}));

const BasicPageHeader: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <div className={classes.section}>

            <Container className={clsx(classes.container, props.containerClassName)}>

                {props.children}

                <div className={classes.background}/>

            </Container>

        </div>
    );
};

export default BasicPageHeader;