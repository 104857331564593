import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import SectionContainer from "../layout/SectionContainer";
import SectionHeading from "../typography/SectionHeading";
import ContactForm from "./ContactForm";
import SocialMediaContainer from "./SocialMediaContainer";

interface Props {
    title: string;
}

const useStyles = makeStyles(() => ({
    socialContainerWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    anchor: {
        display: "block",
        position: "relative",
        top: "-100px",
        visibility: "hidden"
    }
}));

const ContactSection: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <SectionContainer>

            <a
                id="contact"
                className={classes.anchor}/>

            <SectionHeading title={props.title}/>

            <Grid container>

                <Grid
                    item
                    md={6}
                    xs={12}>

                    <ContactForm/>

                </Grid>

                <Grid
                    className={classes.socialContainerWrapper}
                    item
                    md={6}
                    xs={12}>

                    <SocialMediaContainer/>

                </Grid>

            </Grid>

        </SectionContainer>
    );
};

export default ContactSection;
