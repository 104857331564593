import {Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column"
    },
    itemContainer: {
        textAlign: "center",
        transition: theme.transitions.create("margin"),
        [theme.breakpoints.up("lg")]: {
            margin: "2rem 0rem 1rem 0rem"
        },
        [theme.breakpoints.only("md")]: {
            margin: "1.5rem 0rem 0.75rem 0rem"
        },
        [theme.breakpoints.only("sm")]: {
            margin: "1rem 0rem 0.5rem 0rem"
        },
        [theme.breakpoints.only("xs")]: {
            margin: "1rem 0rem 0.5rem 0rem",
            padding: "0rem 2rem"
        },
        "&:nth-child(even)>*:first-child": {
            float: "right",
            transition: theme.transitions.create("margin"),
            [theme.breakpoints.up("lg")]: {
                margin: "0.5rem 0rem 0.5rem 2.5rem"
            },
            [theme.breakpoints.only("md")]: {
                margin: "0.3rem 0rem 0.3rem 1.5rem"
            },
            [theme.breakpoints.only("sm")]: {
                margin: "0.2rem 0rem 0.2rem 1rem"
            }
        }
    },
    title: {
        textAlign: "left",
        color: "rgba(0,0,0,0.87)",
        fontSize: "1.5rem",
        lineHeight: "3rem",
        transition: theme.transitions.create(["font-size", "line-height"]),
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3rem",
            lineHeight: "2.5rem"
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2rem",
            lineHeight: "2rem"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            textAlign: "center"
        }
    },
    image: {
        minWidth: "150px",
        maxWidth: "35%",
        float: "left",
        margin: "0.5rem 2.5rem 0.5rem 0rem",
        transition: theme.transitions.create("margin"),
        [theme.breakpoints.only("md")]: {
            margin: "0.3rem 1.5rem 0.3rem 0rem"
        },
        [theme.breakpoints.down("sm")]: {
            margin: "0.2rem 1rem 0.2rem 0rem"
        },
        [theme.breakpoints.only("xs")]: {
            float: "initial !important",
            margin: "0rem auto 1rem auto",
            maxWidth: "50%"
        }
    }
}));

interface Props {
    items: DetailsItem[];
}

export interface DetailsItem {
    image: string;
    text: React.ReactNode;
    title: string;
}

const DetailsContainer: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <div className={classes.container}>

            {props.items.map(item => (

                <div
                    key={item.title}
                    className={classes.itemContainer}>

                    <img
                        alt=""
                        src={item.image}
                        className={classes.image}/>

                    <Typography
                        className={classes.title}
                        variant="h3">
                        {item.title}
                    </Typography>

                    {item.text}

                </div>

            ))}

        </div>
    )
};

export default DetailsContainer;