import {Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    headline: {
        marginBottom: "1rem",
        color: theme.palette.primary.dark,
        transition: theme.transitions.create(["font-size", "line-height", "margin"]),
        [theme.breakpoints.only("md")]: {
            fontSize: "1.6rem",
            lineHeight: "2.75rem",
            marginTop: "0.25rem"
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.4rem",
            lineHeight: "2.5rem",
            marginTop: "0.5rem"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2rem",
            lineHeight: "2.25rem",
            marginTop: "0.75rem"
        }
    }
}));

interface Props {
    id?: string;
    title: string;
    className?: string;
}

const SectionHeading: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <Typography
            id={props.id}
            className={clsx(classes.headline, props.className)}
            variant="h2">
            {props.title}
        </Typography>
    );
}

export default SectionHeading;